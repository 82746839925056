<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });
});
</script>
<template>
  <section class="about-us">
    <header class="about-us-header cookie-regular">
      <span>About Us</span>
    </header>
    <div class="about-details poppins-regular">
      <span class="about-details-header poppins-bold">Our Mission </span>
      <div class="detail-paragraph">
        <span class="first-letter cookie-regular">A</span>t our core, we believe
        every event should reflect your unique style and personality. By
        understanding your vision and preferences, we bring your ideas to life.
        Whether you have a theme in mind or need guidance, we transform spaces
        into stunning environments for any occasion. From weddings and birthdays
        to corporate functions, we'll make your event unforgettable.
      </div>
      <img class="about-us-image" src="@/assets/images/gallery11.jpg" />
      <div class="detail-paragraph">
        <span class="first-letter cookie-regular">W</span>e constantly explore
        new ideas and techniques to bring fresh, creative designs to each event.
        Our goal is to exceed your expectations and create a lasting impression.
        With a wide range of
        <router-link to="/services" class="services-link poppins-bold"
          >services
        </router-link>
        and decor elements like floor decals, centerpieces, and chair rentals,
        we transform any space into a captivating setting. Every detail is
        meticulously curated to ensure a cohesive and visually stunning
        experience.
      </div>
      <img class="about-us-image" src="@/assets/images/gallery12.jpg" />
      <div class="detail-paragraph">
        <span class="first-letter cookie-regular">W</span>e invite you to browse
        through our
        <router-link to="/gallery" class="gallery-link poppins-bold"
          >gallery
        </router-link>
        to see examples of our work. Whether you're planning an intimate
        gathering or a grand celebration, we would be honored to be a part of
        your special day. Contact us today to schedule a consultation and let us
        bring your event decor dreams to life. We look forward to working with
        you and creating an unforgettable experience for you and your guests.
      </div>
    </div>
  </section>
</template>
<style scoped>
.about-us {
  min-height: calc(100dvh - 300px);
  padding: 40px;
  position: relative;
  margin-top: 25px;
}

.about-us-header {
  margin: 0 auto;
  max-width: 700px;
  font-size: calc(55px + 1vw);
  margin-bottom: 40px;
  position: relative;
}

.about-details {
  margin: 0 auto;
  max-width: 700px;
  text-align: left;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.about-details-header {
  font-size: 30px;
  margin-bottom: 14px;
}

.detail-paragraph {
  font-size: 22px;
}

.first-letter {
  font-size: 40px;
  line-height: 22px;
}

.gallery-link,
.services-link {
  color: #5b2460;
  text-decoration: none;
}

.about-us-image {
  max-width: 500px;
  max-height: 500px;
  object-fit: contain;
  margin: 45px auto;
  border-radius: 10px;
  border: 3px solid #5b2460;
}

@media only screen and (max-width: 768px) {
  .about-us {
    padding: 40px 18px;
  }

  .about-us-header {
    font-size: 50px;
    width: 100%;
  }

  .home-btn {
    max-width: 100%;
  }

  .about-details-header {
    font-size: 28px;
  }

  .about-details {
    max-width: 100%;
  }

  .about-us-image {
    max-width: 100%;
  }

  .detail-paragraph {
    font-size: 18px;
  }

  .first-letter {
    font-size: 38px;
  }
}
</style>
