<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";

const galleryColumns: Ref<string[][]> = ref([]);

const galleryImages: string[] = [
  require("@/assets/images/gallery1.png"),
  require("@/assets/images/gallery2.png"),
  require("@/assets/images/gallery10.jpg"),
  require("@/assets/images/gallery6.png"),
  require("@/assets/images/gallery4.png"),
  require("@/assets/images/gallery5.jpeg"),
  require("@/assets/images/gallery7.jpg"),
  require("@/assets/images/gallery8.jpeg"),
  require("@/assets/images/gallery9.jpg"),
  require("@/assets/images/gallery3.png"),
  require("@/assets/images/gallery11.jpg"),
  require("@/assets/images/gallery15.jpg"),
  require("@/assets/images/gallery16.jpg"),
  require("@/assets/images/gallery17.jpg"),
  require("@/assets/images/gallery18.jpg"),
  require("@/assets/images/gallery13.jpg"),
  require("@/assets/images/gallery24.jpg"),
  require("@/assets/images/gallery12.jpg"),
  require("@/assets/images/gallery14.jpg"),
  require("@/assets/images/gallery22.jpg"),
  require("@/assets/images/gallery23.jpg"),
  require("@/assets/images/gallery25.jpg"),
  require("@/assets/images/gallery26.jpg"),
  require("@/assets/images/gallery27.jpg"),
  require("@/assets/images/gallery28.jpg"),
  require("@/assets/images/gallery29.jpg"),
  require("@/assets/images/gallery30.jpg"),
  require("@/assets/images/gallery31.jpg"),
  require("@/assets/images/gallery32.jpg"),
  require("@/assets/images/gallery23.jpg"),
];

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });

  const mediaQuery = window.matchMedia("(max-width: 768px)");

  setGalleryColumns(mediaQuery);
});

function setGalleryColumns(e: MediaQueryList) {
  if (e.matches) {
    galleryColumns.value = sliceInto(galleryImages, 2);
  } else {
    galleryColumns.value = sliceInto(galleryImages, 3);
  }
}

function sliceInto(arr: string[], n: number) {
  const chunkSize = Math.ceil(arr.length / n);
  const result = [];

  for (let i = 0; i < n; i++) {
    const start = i * chunkSize;
    const end = start + chunkSize;
    result.push(arr.slice(start, end));
  }

  return result;
}
</script>

<template>
  <section class="gallery">
    <header class="gallery-header cookie-regular">
      <span>Gallery</span>
    </header>
    <section class="gallery-grid">
      <div
        v-for="(images, index) in galleryColumns"
        :key="index"
        class="gallery-column"
      >
        <div
          v-for="(photo, photoIndex) in images"
          :key="photoIndex"
          class="photo"
        >
          <img
            :src="photo"
            :alt="`Gallery Image ${index + 1}.${photoIndex + 1}`"
          />
        </div>
      </div>
    </section>
  </section>
</template>

<style scoped>
.gallery {
  min-height: calc(100dvh - 300px);
  margin-top: 25px;
  padding: 40px;
  position: relative;
}

.gallery-header {
  margin: 0 auto;
  max-width: 700px;
  font-size: calc(55px + 1vw);
  margin-bottom: 40px;
  position: relative;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  padding: 20px;
}

.gallery-column {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  gap: 10px;
}

.photo {
  width: 100%;
}

.photo img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .gallery {
    padding: 40px 18px;
  }

  .gallery-header {
    font-size: 50px;
    width: 100%;
    margin-bottom: 30px;
  }

  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }
}
</style>
