<template>
  <section class="about-us">
    <div class="about-us-content">
      <span class="about-us-header cookie-regular">About Us</span>
      <span class="about-us-details poppins-regular">
        Our mission is to bring your vision to life with <br />personalized
        service and meticulous <br />attention to detail. Whether it's a cozy
        dinner party <br />or a church event, we are dedicated to <br />making
        your event truly memorable.
      </span>
      <div class="about-btn-container">
        <router-link to="/about" class="about-btn poppins-bold"
          >Learn More</router-link
        >
      </div>
      <div class="bg-overlay"></div>
      <div class="bg-container">
        <img src="@/assets/about_us_bg.png" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.about-us {
  height: 70dvh;
  max-height: 800px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 40px;
  position: relative;
}

.about-us-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  border: 3px solid #5b2460;
  border-radius: 20px;
  overflow: hidden;
  padding: 40px;
}

.about-us-header {
  width: 100%;
  text-align: left;
  font-size: calc(65px + 1vw);
  line-height: 1;
  position: relative;
  z-index: 3;
}

.about-us-details {
  width: 100%;
  text-align: left;
  font-size: 20px;
  position: relative;
  z-index: 3;
}

.about-btn-container {
  margin-top: 10px;
  width: 100%;
  text-align: left;
  font-size: 20px;
  position: relative;
  z-index: 3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.about-btn-container .about-btn {
  text-decoration: none;
  color: #5b2460;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #fef0ff;
  opacity: 0.8;
}

.bg-container {
  height: 100%;
  position: absolute;
  bottom: -50px;
  right: -70px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 20px;
  z-index: 1;
}

.bg-container img {
  height: 100%;
  object-fit: cover;
  transform: rotateZ(1deg);
}

@media only screen and (max-width: 750px) {
  .about-us {
    height: unset;
    padding: 0;
  }

  .about-us-content {
    border: none;
    align-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
    padding-right: 20px;
    border-radius: unset;
  }

  .about-us-content br {
    display: none;
  }

  .about-btn-container {
    font-size: 16px;
  }

  .bg-container {
    display: none;
  }

  .about-us-header {
    font-size: 60px;
  }

  .about-us-details {
    font-size: 18px;
  }
}

@media all and (orientation: landscape) and (max-width: 999px) {
  .about-us {
    height: 100dvh;
    max-height: 500px;
  }
}

@media all and (orientation: landscape) and (min-width: 1000px) and (max-width: 1450px) {
  .about-us {
    height: 70dvh;
  }

  .about-us-details {
    font-size: 26px;
  }
}

@media all and (orientation: portrait) and (min-width: 751px) and (max-width: 1450px) {
  .about-us {
    height: 40dvh;
  }
}
</style>
