<script setup lang="ts">
import HomeMainComponent from "@/components/home/HomeMainComponent.vue";
import AboutUsComponent from "@/components/home/AboutUsComponent.vue";
import ServicesComponent from "@/components/home/ServicesComponent.vue";
import GalleryComponent from "@/components/home/GalleryComponent.vue";
import ContactUsComponent from "@/components/home/ContactUsComponent.vue";

import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });
});
</script>
<template>
  <div class="home">
    <HomeMainComponent />
    <AboutUsComponent />
    <div class="gallery-images-section">
      <div class="img-container slim">
        <img src="@/assets/images/gallery7.jpg" />
      </div>
      <div class="img-container slim">
        <img src="@/assets/images/gallery9.jpg" />
      </div>
      <div class="img-container slim landscape-start">
        <img src="@/assets/images/gallery6.png" />
      </div>
    </div>
    <ServicesComponent />
    <div class="gallery-images-section">
      <div class="img-container wide">
        <img src="@/assets/images/gallery10.jpg" />
      </div>
      <div class="img-container wide">
        <img src="@/assets/images/gallery4.png" />
      </div>
    </div>
    <GalleryComponent />
    <div class="gallery-images-section">
      <div class="img-container wide">
        <img src="@/assets/images/gallery5.jpeg" />
      </div>
      <div class="img-container wide">
        <img src="@/assets/images/gallery2.png" />
      </div>
    </div>
    <ContactUsComponent />
  </div>
</template>

<style scoped>
.home {
  min-height: 100dvh;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  background: rgb(252, 241, 255);
}

.gallery-images-section {
  max-height: 90dvh;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
}

.img-container {
  height: calc(100% - 100px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  padding: 0 15px;
}

.img-container.end {
  justify-content: flex-end;
}

.img-container.start {
  justify-content: flex-start;
}

.img-container.slim {
  width: 30%;
  height: 90%;
}

.img-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
  border: 3px solid #5b2460;
}

.img-container.slim img {
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .gallery-images-section {
    height: unset;
    max-height: unset;
    padding: 20px;
    gap: 30px;
    background-image: linear-gradient(#fcf1ff, #cfacd8, #fcf1ff);
  }

  .mobile-hide {
    display: none;
  }

  .img-container {
    padding: unset;
    max-width: unset;
  }

  .img-container.wide {
    width: 100%;
    height: unset;
  }

  .img-container.slim {
    width: 100%;
    height: unset;
    flex-shrink: 0;
    max-width: unset;
  }

  .img-container img {
    object-fit: contain;
  }

  .img-container.slim img {
    width: 100%;
  }
}

@media all and (orientation: landscape) {
  .gallery-images-section {
    max-height: unset;
    padding: 20px;
  }
  .gallery-images-section .img-container {
    padding: 0 5px;
  }

  .landscape-start {
    justify-content: flex-start;
  }
}

@media all and (orientation: portrait) and (min-width: 768px) and (max-width: 1024px) {
  .gallery-images-section {
    max-height: unset;
    padding: 20px;
  }
  .gallery-images-section .img-container {
    padding: 0 5px;
  }

  .img-container {
    height: calc(100% - 100px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    padding: 0 15px;
  }

  .img-container.wide {
    height: unset;
  }

  .img-container.end {
    justify-content: flex-end;
  }

  .img-container.start {
    justify-content: flex-start;
  }

  .img-container.slim {
    width: 30%;
    height: 90%;
  }

  .img-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
    border: 3px solid #5b2460;
  }

  .img-container.slim img {
    height: 100%;
    object-fit: cover;
  }
}
</style>
