<script setup lang="ts">
import { onMounted } from "vue";
import ServiceComponent from "@/components/services/ServiceComponent.vue";

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });
});
</script>

<template>
  <section class="services">
    <header class="services-header cookie-regular">
      <span>Services</span>
    </header>
    <div class="services-details poppins-regular">
      <span class="detail-header poppins-bold">Party Rentals</span>
      <ServiceComponent
        name="Floor Decals"
        description="Our floor decals add a unique touch to your event by transforming
            the floor into a visually captivating space. Whether you want to
            create a custom design, showcase your logo, or guide guests through
            a specific area, our floor decals are the perfect solution."
        image="fd"
      />
      <ServiceComponent
        name="Chair Rentals"
        description="Our chair rentals offer a variety of options to suit
            different themes and aesthetics. From elegant and classic designs to
            modern and contemporary styles, we have a wide selection to choose
            from."
        image="cr"
      />
      <ServiceComponent
        name="360 Photo Booth Rentals"
        description="Capture the memories of your event in a fun and interactive way
            with our 360 photo booth rentals, allowing your guests to
            create unique and immersive photos. It's a great way to entertain
            your guests and provide them with lasting mementos."
        image="pb"
      />
      <ServiceComponent
        name="Balloon Decor"
        description="Our balloon decor services include
            balloon arches, balloon columns, balloon centerpieces, and more. We
            can create stunning balloon installations that complement your event
            theme and create a festive atmosphere."
        image="bd"
      />
    </div>
    <div class="services-details poppins-regular">
      <span class="detail-header poppins-bold">Occasions</span>
      <ServiceComponent
        name="Dinner Parties"
        description="Whether it's an intimate gathering or a large-scale dinner event, we
          can create the perfect ambiance with our event decor services."
        image="dp"
      />
      <ServiceComponent
        name="Weddings"
        description="Make your wedding day truly magical with our personalized event decor.
          From elegant floral arrangements to romantic lighting, we can help
          create the wedding of your dreams."
        image="w"
      />
      <ServiceComponent
        name="Birthday Parties"
        description="Celebrate your special day in style with our creative and vibrant
          event decor. We can customize the decor to match your theme and create
          a memorable experience for you and your guests."
        image="b"
      />
      <ServiceComponent
        name="Graduations"
        description="Commemorate this important milestone as we create a festive and celebratory atmosphere that reflects your
          achievements and sets the stage for an unforgettable graduation party."
        image="g"
      />
      <ServiceComponent
        name="Anniversaries"
        description="Whether it's a milestone anniversary or a special date, we can help
          create a romantic and memorable atmosphere for you and your loved one
          to celebrate your journey together."
        image="a"
      />
      <ServiceComponent
        name="Church Events"
        description="Enhance your church events with our event decor services. From holiday
          celebrations to community gatherings, we can create a warm and
          welcoming environment that brings people together."
        image="che"
      />
      <ServiceComponent
        name="Couples Events"
        description="Whether it's a date night, proposal, or anniversary celebration, we
          can create a romantic and intimate setting for you and your partner to
          enjoy a special moment together."
        image="c"
      />
    </div>
  </section>
</template>

<style scoped>
.services {
  min-height: calc(100dvh - 300px);
  margin-top: 25px;
  padding: 40px;
  position: relative;
}

.services-header {
  margin: 0 auto;
  max-width: 700px;
  font-size: calc(55px + 1vw);
  position: relative;
}

.services-details {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: center;
  margin: 20px auto;
}

.detail {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  width: 300px;
  border: 2px solid #5b2460;
  border-radius: 8px;
}

.detail-header {
  width: 100%;
  font-size: 30px;
  color: #260829;
  text-align: left;
}

.detail-text-header {
  color: #410d46;
  width: 100%;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-bottom: 2px solid #5b2460;
  background-image: linear-gradient(to bottom, #fcf1ff, #5b2460),
    linear-gradient(to right, #fcf1ff, #5b2460, #cfacd8);
  background-blend-mode: screen;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.detail-description {
  flex: 1;
  padding: 10px;
  text-align: left;
  background: rgb(91, 36, 96, 0.1);
}

@media only screen and (max-width: 768px) {
  .services {
    padding: 40px 18px;
  }

  .services-header {
    font-size: 50px;
    width: 100%;
  }

  .detail-header {
    font-size: 24px;
  }
}
</style>
