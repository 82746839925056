<script setup lang="ts">
import { onUnmounted, watch, nextTick, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const expanded = ref(false);

const handleScroll = () => {
  const navbar = document.getElementById("navbar");
  if (!navbar) return;

  if (window.scrollY < 75 && !navbar.classList.contains("transparent")) {
    navbar.classList.add("transparent");
    return;
  }

  if (window.scrollY >= 75 && navbar.classList.contains("transparent")) {
    navbar.classList.remove("transparent");
  }
};

const manageScrollListener = (shouldAdd: boolean) => {
  if (shouldAdd) {
    window.addEventListener("scroll", handleScroll);
  } else {
    window.removeEventListener("scroll", handleScroll);
  }
};

const applyInitialClass = () => {
  const navbar = document.getElementById("navbar");
  if (navbar) {
    if (route.path === "/") {
      navbar.classList.add("transparent");
    } else {
      navbar.classList.remove("transparent");
    }
  }
};

watch(
  () => route.path,
  (newPath) => {
    applyInitialClass();

    if (newPath === "/") {
      manageScrollListener(true);
    } else {
      manageScrollListener(false);
    }

    nextTick(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    });

    const mainNav = document.getElementById("mainNav");

    if (mainNav && mainNav.classList.contains("show")) {
      mainNav.classList.remove("show");
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  manageScrollListener(false);
});

function removeTransparent() {
  const navbar = document.getElementById("navbar");

  if (navbar) {
    navbar.classList.remove("transparent");
  }
}

function checkTransparent() {
  expanded.value = !expanded.value;

  if (expanded.value && route.path === "/") {
    removeTransparent();
  } else if (!expanded.value && route.path === "/") {
    handleScroll();
  }
}
</script>

<template>
  <nav
    class="main-nav navbar transparent fixed-top navbar-expand-lg"
    id="navbar"
  >
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/ip_logo_transparent.png" />
      </router-link>
      <button
        @click="checkTransparent"
        class="navbar-toggler ms-auto"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mainNav"
        aria-controls="mainNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="mainNav">
        <div class="navbar-nav ms-auto">
          <router-link to="/" exact active-class="active" class="nav-link">
            Home
          </router-link>
          <router-link to="/about" exact active-class="active" class="nav-link"
            >About Us</router-link
          >
          <router-link
            to="/services"
            exact
            active-class="active"
            class="nav-link"
            >Services</router-link
          >
          <router-link
            to="/gallery"
            exact
            active-class="active"
            class="nav-link"
            >Gallery</router-link
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.main-nav {
  background-color: #fcf1ff;
  z-index: 100000;
}

.main-nav.transparent {
  background-color: transparent;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.nav-link {
  color: rgb(106, 106, 106);
}

.nav-link:active {
  color: black;
}

.main-nav.transparent .navbar-brand {
  display: none;
}

.navbar-brand img {
  height: 55px;
  object-fit: contain;
}

.navbar-toggler {
  border-color: transparent;
}
</style>
