<script setup lang="ts">
function scrollToContactUs() {
  const element = document.getElementById("contact-us");

  if (element) {
    element.scrollIntoView({ block: "start", behavior: "instant" });
  }
}
</script>

<template>
  <main class="main-component">
    <img class="bg-image" src="@/assets/innovation_perfection_home_bg.png" />
    <div class="bg-overlay"></div>
    <div class="main-content">
      <header class="main-header anton-sc-regular">
        Innovative Perfection
      </header>
      <span class="main-subheading noto-serif-display-normal"
        >Party Rentals & 360 Photos</span
      >
      <button
        @click="scrollToContactUs"
        type="button"
        class="contact-us-btn cookie-regular"
      >
        <span>Contact Us</span>
      </button>
    </div>
  </main>
</template>

<style scoped>
.main-component {
  padding-top: 70px;
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0 10px;
  background: #fcf1ff;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #fcf1ff;
  opacity: 0.5;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  mask-image: linear-gradient(180deg, #fcf1ff 50%, transparent);
  -webkit-mask-image: linear-gradient(180deg, #fcf1ff 50%, transparent);
}

.main-content {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.main-header {
  font-size: calc(60px + 1vw);
  color: #5b2460;
  white-space: nowrap;
  width: 100%;
}

.main-subheading {
  width: 100%;
  margin: 20px 0 50px 0;
  font-size: calc(20px + 1vw);
  font-weight: bold;
}

.contact-us-btn {
  border: 1px solid;
  border-color: transparent;
  border-radius: 6px;
  background: rgb(91, 36, 96, 0.6);
  padding: 5px 20px;
  font-size: calc(14px + 1vw);
  max-height: calc(30px + 1vw);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 750px) {
  .main-header {
    white-space: unset;
    line-height: 1.1;
    font-size: calc(52px + 1vw);
  }

  .main-subheading {
    font-size: calc(18px + 1vw);
  }

  .contact-us-btn {
    font-size: calc(24px + 1vw);
  }
}
</style>
