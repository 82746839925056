<template>
  <section class="gallery">
    <div class="gallery-content">
      <span class="gallery-header cookie-regular">Gallery</span>
      <span class="gallery-details poppins-regular">
        Explore our gallery to see the magic we create. <br />Each photo tells a
        story of unforgettable <br />moments and exceptional design, reflecting
        <br />our dedication to excellence.
      </span>
      <div class="gallery-btn-container">
        <router-link to="/gallery" class="gallery-btn poppins-bold">
          View Gallery
        </router-link>
      </div>
      <div class="bg-overlay"></div>
      <div class="bg-container">
        <img src="@/assets/gallery_bg.png" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.gallery {
  height: 70dvh;
  max-height: 800px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 40px;
  position: relative;
}

.gallery-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  border: 3px solid #5b2460;
  border-radius: 20px;
  overflow: hidden;
  padding: 40px;
}

.gallery-header {
  width: 100%;
  text-align: left;
  font-size: calc(65px + 1vw);
  line-height: 1;
  position: relative;
  z-index: 3;
}

.gallery-details {
  width: 100%;
  text-align: left;
  font-size: 20px;
  position: relative;
  z-index: 3;
}

.gallery-btn-container {
  margin-top: 10px;
  width: 100%;
  text-align: left;
  font-size: 20px;
  position: relative;
  z-index: 3;
  color: #5b2460;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.gallery-btn-container .gallery-btn {
  text-decoration: none;
  color: #5b2460;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #fef0ff;
  opacity: 0.8;
}

.bg-container {
  height: calc(100% + 10px);
  position: absolute;
  bottom: -10px;
  right: -20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
  min-width: 50%;
}

.bg-container img {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 750px) {
  .gallery {
    height: unset;
    padding: 0;
  }

  .gallery-content {
    border: none;
    align-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
    font-size: 16px;
    padding-right: 20px;
    border-radius: unset;
  }

  .gallery-content br {
    display: none;
  }

  .gallery-btn-container {
    font-size: 16px;
  }

  .bg-container {
    display: none;
  }

  .gallery-header {
    font-size: 60px;
  }

  .gallery-details {
    font-size: 18px;
  }
}

@media all and (orientation: landscape) and (max-width: 999px) {
  .gallery {
    height: 100dvh;
    max-height: 500px;
  }
}

@media all and (orientation: landscape) and (min-width: 1000px) and (max-width: 1450px) {
  .gallery {
    height: 70dvh;
  }

  .gallery-details {
    font-size: 26px;
  }
}

@media all and (orientation: portrait) and (min-width: 768px) and (max-width: 1450px) {
  .gallery {
    height: 40dvh;
  }
}
</style>
