<template>
  <section class="services">
    <div class="services-content">
      <span class="services-header cookie-regular">Services</span>
      <span class="services-details poppins-regular">
        Our comprehensive services cover everything <br />from chair rentals and
        360 photo booths to floor <br />decals and dinner parties. We cater to
        weddings, <br />birthdays, graduations, anniversaries, church
        <br />events, private events, and more.
      </span>
      <div class="services-btn-container">
        <router-link to="/services" class="services-btn poppins-bold"
          >View Services</router-link
        >
      </div>
      <div class="bg-overlay"></div>
      <div class="bg-container">
        <img src="@/assets/services_bg.png" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.services {
  height: 70dvh;
  max-height: 800px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 40px;
  position: relative;
}

.services-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  border: 3px solid #5b2460;
  border-radius: 20px;
  overflow: hidden;
  padding: 40px;
}

.services-header {
  width: 100%;
  text-align: right;
  font-size: calc(65px + 1vw);
  line-height: 1;
  position: relative;
  z-index: 3;
}

.services-details {
  width: 100%;
  text-align: right;
  font-size: 20px;
  position: relative;
  z-index: 3;
}

.services-btn-container {
  margin-top: 10px;
  width: 100%;
  text-align: right;
  font-size: 20px;
  position: relative;
  z-index: 3;
  color: #5b2460;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.services-btn-container .services-btn {
  text-decoration: none;
  color: #5b2460;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #fef0ff;
  opacity: 0.8;
}

.bg-container {
  height: 100%;
  position: absolute;
  bottom: -5px;
  left: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 20px;
  z-index: 1;
}

.bg-container img {
  height: 100%;
  object-fit: cover;
  transform: rotateZ(1deg);
}

@media only screen and (max-width: 750px) {
  .services {
    height: unset;
    padding: 0;
  }

  .services-content {
    border: none;
    align-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
    font-size: 16px;
    padding-right: 20px;
    border-radius: unset;
  }

  .services-details br {
    display: none;
  }

  .services-btn-container {
    font-size: 16px;
  }

  .bg-container {
    display: none;
  }

  .services-header {
    font-size: 60px;
    text-align: right;
  }

  .services-details {
    font-size: 18px;
    text-align: right;
  }
}

@media all and (orientation: landscape) and (max-width: 999px) {
  .services {
    height: 100dvh;
    max-height: 500px;
  }
}

@media all and (orientation: landscape) and (min-width: 1000px) and (max-width: 1450px) {
  .services {
    height: 70dvh;
  }

  .services-details {
    font-size: 26px;
  }
}

@media all and (orientation: portrait) and (min-width: 768px) and (max-width: 1450px) {
  .services {
    height: 40dvh;
  }
}
</style>
